import React, { ComponentProps, useContext, useRef } from "react";
import Styles from "./SearchInput.module.scss";

// Components
import {
    components,
    ControlProps,
    MenuProps,
    InputProps,
    OptionProps,
    SingleValueProps,
    PlaceholderProps
} from "react-select";
import { SelectOnChangeType, SelectOption } from "@vahak/core-ui/dist/components/Select";
import Search from "@vahak/core-ui/dist/components/Search";
// Constants
// Methods
// Hooks
// Assets
import MapPinGrey from "@vahak/core/dist/icons/LocationPinWithBg.svg";
import { generateSelectOption } from "../../../../methods/uiUtil";
import { AppContext } from "@vahak/core/dist/app-context";
import CurrentLocation from "../../../auth/current-location/CurrentLocation";
import useMediaQuery from "@vahak/core-ui/dist/hooks/useMediaQuery";

interface SearchInputProps<T>
    extends Pick<ComponentProps<typeof Search>, "loadOptions" | "name" | "id" | "placeholder"> {
    containerIcon?: React.ReactNode;
    optionIcon?: React.ReactNode;
    value?: T;
    valueText?: string;
    openPopUpView?: Function;
    onChange?: (newValue?: T) => void;
    showCurrentLocation?: boolean;
}

const SearchInput = <T,>({
    loadOptions,
    containerIcon,
    optionIcon,
    id,
    name,
    placeholder,
    value,
    valueText,
    openPopUpView,
    onChange,
    showCurrentLocation
}: SearchInputProps<T>) => {
    const isMobileScreen = useMediaQuery({ queryType: "mobile" });
    const ref = useRef<any>(null);
    const handleChange: SelectOnChangeType = (v: any) => {
        onChange?.(v?.value);
    };
    const currentValue = valueText ? generateSelectOption(valueText, value) : undefined;
    const { globalData } = useContext(AppContext);
    const userPlaceInfo = globalData?.["userPlaceInfo"] as undefined | T;

    const onClickGetCurrentLocation = () => {
        onChange?.(userPlaceInfo);
    };

    return (
        <div className={Styles.main}>
            <Search
                id={id}
                name={name}
                placeholder={placeholder}
                value={currentValue as any}
                components={
                    {
                        DropdownIndicator: () =>
                            showCurrentLocation && !!userPlaceInfo && !currentValue ? (
                                <CurrentLocation
                                    id={id + "-current"}
                                    onClick={onClickGetCurrentLocation}
                                    showLabel={false}
                                />
                            ) : null,
                        Control: ({ children, ...props }: ControlProps<any, false>) => {
                            return (
                                <components.Control {...props} className={Styles.locationControl}>
                                    {containerIcon}
                                    {children}
                                </components.Control>
                            );
                        },
                        Input: (props: InputProps<SelectOption<T>>) => {
                            return <components.Input {...props} className={Styles.locationInput} />;
                        },
                        Menu: (props: MenuProps<SelectOption<T>>) => {
                            if (!props?.selectProps?.inputValue || props?.selectProps?.isLoading) return null;
                            return <components.Menu {...props} className={Styles.locationMenu} />;
                        },
                        Option: ({ children, ...rest }: OptionProps<SelectOption<T>>) => {
                            return (
                                <components.Option {...rest} className={Styles.locationOption}>
                                    <span className={Styles.locationOptionIcon}>{optionIcon ?? <MapPinGrey />}</span>
                                    {children}
                                </components.Option>
                            );
                        },
                        SingleValue: ({ children, ...props }: SingleValueProps<SelectOption<T>>) => {
                            return (
                                <components.SingleValue {...props} className={Styles.locationValue}>
                                    {valueText}
                                </components.SingleValue>
                            );
                        },
                        Placeholder: ({ children, ...props }: PlaceholderProps<SelectOption<T>>) => {
                            return (
                                <components.Placeholder {...props} className={Styles.placeholder}>
                                    {children}
                                </components.Placeholder>
                            );
                        }
                    } as any
                }
                loadOptions={loadOptions}
                onChange={handleChange}
                {...(!!openPopUpView && {
                    ref: ref,
                    menuIsOpen: false,
                    onMenuOpen: () => {
                        openPopUpView?.();
                        ref?.current?.blur?.();
                    }
                })}
                option={[]}
                isClearable={!isMobileScreen}
            />
        </div>
    );
};

export default SearchInput;
